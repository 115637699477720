import { api, log } from ":mods";
import { ENDPOINTS } from "../const";

export async function StudentSignup(data) {
  return api
    .postAuth(ENDPOINTS.STUDENT_ONLINE_SIGNUP, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((res) => {
      console.log("res :: ", res);
      const res_data = res.response.data;
      const errors = res_data?.error;
      const exhibition_pdf = errors?.[0]?.exhibition_pdf?.[0];
      log.all.error("submission error :: ", errors);
      return Promise.reject(exhibition_pdf ?? "an error occured");
    });
}

export async function TutorSignup(form_data) {
  return api
    .postAuth(ENDPOINTS.TUTOR_ONLINE_SIGNUP, form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((res) => {
      console.log("res :: ", res);
      const res_data = res.response.data;
      const errors = res_data?.error;
      const exhibition_pdf = errors?.[0]?.exhibition_pdf?.[0];
      log.all.error("submission error :: ", errors);
      return Promise.reject(exhibition_pdf ?? "an error occured");
    });
}
